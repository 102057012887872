import * as Sentry from '@sentry/sveltekit';

Sentry.init({
	dsn: 'https://5598a8f300b6487780a5abdf7fb2b7e3@o725340.ingest.sentry.io/6163165',
	tracesSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [Sentry.replayIntegration()],
	environment: import.meta.env.VITE_NODE_ENV,
	ignoreErrors: [
		'Access is denied for this document',
		'Extension context invalidated.',
		'ResizeObserver loop limit exceeded',
		'Invalid combination'
	]
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry();
